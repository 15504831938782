import { useQuery } from 'react-query';
import { ReactQueryKey } from '@enums';
import { apiErrorHandler } from '@utils';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';
import { SystemClusterRecord } from '@generated/types/graphql';

export const useSystemsClusters = (ids: number[], clusterDistance: number) =>
  useQuery<SystemClusterRecord[]>({
    queryKey: [ReactQueryKey.System, 'useSystemsClusters', ids, { clusterDistance }],
    queryFn: async () => {
      try {
        return (
          await postGraphql<{ systemCluster: SystemClusterRecord[] }>(
            gql`
              query SYSTEM_CLUSTER_QUERY($ids: [Int!]!, $clusterDistance: Float!) {
                systemCluster(systemIds: $ids, clusterDistance: $clusterDistance) {
                  center {
                    x
                    y
                  }
                  cluster
                  clusterSize
                  clusterStatuses
                  sampleSystemId
                }
              }
            `,
            {
              ids,
              clusterDistance
            }
          )
        ).systemCluster;
      } catch (e) {
        throw apiErrorHandler('Error fetching systems clusters', e);
      }
    },
    keepPreviousData: true,
    initialData: [],
    enabled: ids.length > 0 && clusterDistance > 0
  });
