import { useQuery } from 'react-query';
import { ReactQueryKey } from '@enums';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';
import { IntegrationStat } from '@generated/types/graphql';

export const useIntegrationStats = (integrationId: number) =>
  useQuery({
    queryKey: [ReactQueryKey.Integration, 'useIntegrationStats', integrationId],
    queryFn: async () => {
      return (
        await postGraphql<{ integrationStats: IntegrationStat[] }>(
          gql`
            query INTEGRATION_STATS_QUERY($integrationId: Int!) {
              integrationStats(condition: { integrationId: $integrationId }) {
                total
                monitored
                medianMonthlyNew
              }
            }
          `,
          {
            integrationId
          }
        )
      ).integrationStats[0];
    },
    initialData: { total: 0, monitored: 0, medianMonthlyNew: 0 } as IntegrationStat,
    keepPreviousData: true
  });
