import { Integration, IntegrationProvider } from '@generated/types/graphql';
import { useQuery } from 'react-query';
import { ReactQueryKey } from '@enums';
import { apiErrorHandler } from '@utils';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';

export const useIntegration = (args: { companyId: number; provider: IntegrationProvider }) =>
  useQuery<Integration>(
    [ReactQueryKey.Integration, 'useIntegration', args],
    async () => {
      try {
        return (
          (
            await postGraphql<{ integrations: Integration[] }>(
              gql`
                query INTEGRATION_BY_PROVIDER_QUERY($companyId: Int!, $provider: IntegrationProvider!) {
                  integrations(condition: { companyId: $companyId, provider: $provider }) {
                    id
                    companyId
                    provider
                    active
                  }
                }
              `,
              args
            )
          ).integrations[0] || null
        );
      } catch (e) {
        throw apiErrorHandler('Error fetching integration', e);
      }
    },
    {
      initialData: null
    }
  );
