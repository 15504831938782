import { DateTime } from 'luxon';
import { UseQueryOptions } from 'react-query/types/react/types';
import { useQuery } from 'react-query';
import { ReactQueryKey } from '@enums';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';
import { apiErrorHandler } from '@utils';
import { Geolocation } from '@generated/types/graphql';

export type ActualRoutes = { [userId: number]: Geolocation[] };

export const useActualRoutes = (args: { userIds: number[]; day: DateTime }, opts: UseQueryOptions<ActualRoutes>) =>
  useQuery<ActualRoutes>(
    [ReactQueryKey.Geolocation, 'useActualRoutes', args],
    async () => {
      try {
        const variables = {
          ...args,
          startAt: args.day.startOf('day').toISO(),
          endAt: args.day.endOf('day').toISO()
        };

        const data = await postGraphql<{ geolocations: Geolocation[] }>(
          gql`
            query ACTUAL_ROUTES_QUERY($userIds: [Int!]!, $startAt: Datetime!, $endAt: Datetime!) {
              geolocations(
                filter: {
                  userId: { in: $userIds }
                  timestamp: { greaterThanOrEqualTo: $startAt, lessThanOrEqualTo: $endAt }
                }
                orderBy: [ID_ASC]
              ) {
                id
                latitude
                longitude
                userId
                taskId
                timestamp
              }
            }
          `,
          variables
        );

        return data.geolocations.reduce((acc, location) => {
          if (!acc[location.userId]) {
            acc[location.userId] = [];
          }

          acc[location.userId].push(location);

          return acc;
        }, {} as ActualRoutes);
      } catch (e) {
        throw apiErrorHandler('Error fetching user route', e);
      }
    },
    {
      initialData: {},
      keepPreviousData: true,
      ...opts
    }
  );
