import { ReactQueryKey } from '@enums';
import { useInfiniteQuery } from 'react-query';
import { selectWorkspaceId } from '@state/selectors';
import { Contact, ContactsConnection, ContactsOrderBy } from '@generated/types/graphql';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';
import { errorHandler } from '@services/api/helpers';
import { useAppSelector } from '..';

type PaginatedContacts = {
  contacts: Contact[];
  pageParam: number;
  totalCount: number;
};

type Params = {
  perPage: number;
  query: string;
  orderBy: ContactsOrderBy[];
};

export const useContactsList = ({ perPage = 10, query = '', orderBy = [ContactsOrderBy.CreatedAtDesc] }: Params) => {
  const companyId = useAppSelector(selectWorkspaceId);

  return useInfiniteQuery<PaginatedContacts>(
    [ReactQueryKey.WorkspaceContacts, companyId, perPage, query, orderBy],
    async ({ pageParam = 0 }) => {
      try {
        const { contactsConnection } = await postGraphql<{ contactsConnection: ContactsConnection }>(
          gql`
            query CONTACTS_LIST_QUERY(
              $companyId: Int!
              $first: Int!
              $offset: Int!
              $orderBy: [ContactsOrderBy!]
              $search: String
            ) {
              contactsConnection(
                first: $first
                offset: $offset
                orderBy: $orderBy
                filter: { companyId: { equalTo: $companyId }, searchString: { includesInsensitive: $search } }
                condition: { withAccess: true }
              ) {
                nodes {
                  id
                  companyId
                  createdAt
                  emails
                  name
                  phones
                  createdByUser {
                    id
                    email
                    firstName
                    lastName
                    avatarUrl
                  }
                  contactProjects {
                    id
                    type
                    title
                  }
                }
                totalCount
              }
            }
          `,
          {
            companyId,
            first: perPage,
            offset: pageParam * perPage,
            orderBy,
            search: query
          }
        );

        return {
          totalCount: contactsConnection.totalCount,
          contacts: contactsConnection.nodes,
          pageParam: pageParam + 1
        };
      } catch (e) {
        throw errorHandler(e);
      }
    },
    {
      getNextPageParam: (lastPage) => lastPage.pageParam + 1,
      keepPreviousData: true
    }
  );
};
