import React, { useCallback, useMemo, useState } from 'react';
import { Button, ButtonVariant, IconButton } from '@kit/ui/Button';
import {
  CheckboxField,
  CheckboxGroupField,
  Form,
  FormValidationRules,
  InputField,
  RichTextField,
  SelectField,
  useForm
} from '@kit/components/Form';
import { useToast } from '@hooks/useToast';
import { useUpdateCompanyNotification } from '@hooks/workspace/notifications/useUpdateCompanyNofification';
import { PortalNotificationEvent } from '@hooks/workspace/notifications/usePortalNotifications';
import { useAppSelector } from '@hooks/store';
import { selectCompanyById, selectWorkspaceId } from '@state/selectors';
import { Plus } from 'react-feather';
import { TrashIcon } from '@kit/ui/icons/Trash';
import { TabItem, Tabs } from '@kit/ui/Tabs';
import {
  clearQuillValue,
  clearQuillValueForSms,
  convertQuillFormatToText,
  convertTextToQuillFormat,
  isQuilValueEmpty,
  replacePatternsInString,
  TOKEN_TEXT_PATTERN
} from '@utils/quill';
import { ModalBody, ModalFooter } from '@common/PromiseModal';
import { TOKENS_BY_EVENT_TYPE } from './tokens';
import { config } from '../../../../../../config';
import { PreviewContainer, PreviewLabel, Previews, ReminderSettingRow, RemindersSettings, Templates } from './styled';
import { EmailTemplate, getButtonHtml } from './EmailTemplate';

interface FormValues {
  deliveryMethod: string[];
  offset1Time: number;
  offset1Unit: { label: string; value: string };
  offset1Email: boolean;
  offset1Sms: boolean;
  offset2Time: number;
  offset2Unit: { label: string; value: string };
  offset2Email: boolean;
  offset2Sms: boolean;
  emailNotificationSubject: string;
  emailNotificationText: string;
  smsNotificationText: string;
}

const DELIVERY_METHODS = [
  { label: 'Email', value: 'email', settingsKey: 'emailEnabled' },
  { label: 'SMS', value: 'sms', settingsKey: 'smsEnabled' }
];

const TIME_UNIT_OPTIONS = [
  { label: 'hours before', value: 'HOURS' },
  { label: 'days before', value: 'DAYS' }
];

const TEMPLATE_TABS: TabItem[] = [
  { title: 'Email', id: 'email' },
  { title: 'SMS', id: 'sms' }
];

const getDefaultValues = (event: PortalNotificationEvent) => {
  return {
    deliveryMethod: DELIVERY_METHODS.filter((method) => event.settings[method.settingsKey]).map(
      (method) => method.value
    ),
    offset1Time: event.settings.offset1Time,
    offset1Unit:
      TIME_UNIT_OPTIONS.find((option) => option.value === event.settings.offset1Unit) ?? TIME_UNIT_OPTIONS[0],
    offset1Email: event.settings.offset1Email ?? false,
    offset1Sms: event.settings.offset1Sms ?? false,
    offset2Time: event.settings.offset2Time,
    offset2Unit:
      TIME_UNIT_OPTIONS.find((option) => option.value === event.settings.offset2Unit) ?? TIME_UNIT_OPTIONS[0],
    offset2Email: event.settings.offset2Email ?? false,
    offset2Sms: event.settings.offset2Sms ?? false,
    emailNotificationSubject: convertTextToQuillFormat(event.settings.emailNotificationSubject),
    emailNotificationText: convertTextToQuillFormat(event.settings.emailNotificationText),
    smsNotificationText: convertTextToQuillFormat(event.settings.smsNotificationText)
  };
};
interface Props {
  event: PortalNotificationEvent;
  onClose: () => void;
}

export const EventForm = ({ event, onClose }: Props) => {
  const companyId = useAppSelector(selectWorkspaceId);
  const company = useAppSelector((state) => selectCompanyById(state, companyId));
  const portalUrl = `${config.portalUrl}/${company?.subdomainName ?? ''}`;

  const getTokens = useCallback(() => {
    return TOKENS_BY_EVENT_TYPE[event.settings.eventType] ?? [];
  }, [event.settings.eventType]);

  const previewTextWithValues = (template: string, isPlainText = false) => {
    const tokens = getTokens();

    return replacePatternsInString(template, TOKEN_TEXT_PATTERN, (...rest) => {
      const tokenId = rest[2];

      let value;

      if (tokenId === 'accessUrl') {
        value = isPlainText ? portalUrl : getButtonHtml(portalUrl, 'Click here to view');
      } else if (tokenId === 'company.name') {
        value = company?.name;
      } else {
        const token = tokens.find((token) => token.id === tokenId);

        if (!token) {
          value = '';
        } else {
          value = token?.exampleValue ?? token?.value;
        }
      }

      return value;
    });
  };

  const [isSecondReminderShown, setIsSecondReminderShown] = useState(
    event.settings.offset2Email || event.settings.offset2Sms
  );
  const [selectedTab, setSelectedTab] = useState(TEMPLATE_TABS[0]);

  const isSchedulable = event.eventSchema.scheduled;

  const { mutateAsync: update } = useUpdateCompanyNotification();

  const { showError } = useToast();

  const postForm = async (values: FormValues) => {
    if (!isSchedulable && !values.deliveryMethod.length) {
      showError('At least one delivery method should be selected');

      return;
    }

    await update({
      entityType: event.settings.entityType,
      eventType: event.settings.eventType,
      emailEnabled: isSchedulable
        ? values.offset1Email || values.offset2Email
        : values.deliveryMethod.includes('email'),
      smsEnabled: isSchedulable ? values.offset1Sms || values.offset2Sms : values.deliveryMethod.includes('sms'),
      offset1Time: values.offset1Time,
      offset1Unit: values.offset1Unit.value,
      offset1Email: values.offset1Email ?? false,
      offset1Sms: values.offset1Sms ?? false,
      offset2Time: isSecondReminderShown ? values.offset2Time : null,
      offset2Unit: isSecondReminderShown ? values.offset2Unit.value : null,
      offset2Email: isSecondReminderShown ? values.offset2Email : false,
      offset2Sms: isSecondReminderShown ? values.offset2Sms : false,
      emailNotificationSubject: clearQuillValue(convertQuillFormatToText(values.emailNotificationSubject)),
      emailNotificationText: convertQuillFormatToText(values.emailNotificationText),
      smsNotificationText: clearQuillValueForSms(convertQuillFormatToText(values.smsNotificationText))
    });

    onClose();
  };

  const { form, handleSubmit } = useForm<FormValues>({
    onSubmit: postForm,
    defaultValues: getDefaultValues(event)
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { control, reset, watch } = form;

  const rules = useMemo<FormValidationRules<FormValues>>(() => {
    return {
      deliveryMethod: {
        isRequired: true
      },
      offset1Time: {
        min: 1
      },
      offset2Time: {
        min: 1
      },
      emailNotificationSubject: {
        isRequired: true,
        validate: (value) => {
          if (value && isQuilValueEmpty(value as string)) {
            return 'This is required';
          }

          return undefined;
        }
      },
      emailNotificationText: {
        isRequired: true,
        validate: (value) => {
          if (value && isQuilValueEmpty(value as string)) {
            return 'This is required';
          }

          return undefined;
        }
      },
      smsNotificationText: {
        isRequired: true,
        validate: (value) => {
          if (value && isQuilValueEmpty(value as string)) {
            return 'This is required';
          }

          return undefined;
        }
      }
    };
  }, []);

  const handleAddSecondReminder = useCallback(() => {
    setIsSecondReminderShown(true);

    reset((prev) => ({
      ...prev,
      offset2Time: 1,
      offset2Unit: TIME_UNIT_OPTIONS[0],
      offset2Email: true,
      offset2Sms: false
    }));
  }, [reset]);

  const handleRemoveSecondReminder = useCallback(() => {
    setIsSecondReminderShown(false);
  }, []);

  const [emailNotificationText, smsNotificationText] = watch(['emailNotificationText', 'smsNotificationText']);

  return (
    <Form rules={rules} onSubmit={handleSubmit}>
      <ModalBody width="815px">
        {!isSchedulable && (
          <CheckboxGroupField
            layout="row"
            name="deliveryMethod"
            label="Delivery methods"
            options={DELIVERY_METHODS}
            control={control}
          />
        )}

        {isSchedulable && (
          <RemindersSettings>
            <ReminderSettingRow>
              <div>Send a reminder</div>
              <InputField name="offset1Time" type="number" control={control} />
              <SelectField
                name="offset1Unit"
                options={TIME_UNIT_OPTIONS}
                control={control}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
                isClearable={false}
              />
              <div>the appointment by</div>
              <CheckboxField name="offset1Email" label="Email" control={control} />
              <CheckboxField name="offset1Sms" label="SMS" control={control} />
            </ReminderSettingRow>

            {!isSecondReminderShown && (
              <div onClick={handleAddSecondReminder}>
                <Button variant={ButtonVariant.Flat}>
                  <Plus size={16} />
                  Add reminder
                </Button>
              </div>
            )}

            {isSecondReminderShown && (
              <ReminderSettingRow>
                <div>Send a reminder</div>
                <InputField name="offset2Time" type="number" control={control} />
                <SelectField
                  name="offset2Unit"
                  options={TIME_UNIT_OPTIONS}
                  control={control}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  isClearable={false}
                />
                <div>the appointment by</div>
                <CheckboxField name="offset2Email" label="Email" control={control} />
                <CheckboxField name="offset2Sms" label="SMS" control={control} />
                <IconButton onClick={handleRemoveSecondReminder} variant={ButtonVariant.Flat}>
                  <TrashIcon color="#D54855" size={16} />
                </IconButton>
              </ReminderSettingRow>
            )}
          </RemindersSettings>
        )}

        <Templates>
          <Tabs variant="outline" tabs={TEMPLATE_TABS} selected={selectedTab.id} onChange={setSelectedTab} />

          {selectedTab.id === 'email' && (
            <Previews>
              <div>
                <RichTextField
                  getTokens={getTokens}
                  isOneLine
                  isToolbarHiddenCompletely
                  placeholder=""
                  label="Email subject"
                  name="emailNotificationSubject"
                  control={control}
                  clearOnUnmount={false}
                />

                <RichTextField
                  getTokens={getTokens}
                  isToolbarHiddenCompletely
                  placeholder=""
                  label="Email message"
                  name="emailNotificationText"
                  control={control}
                  clearOnUnmount={false}
                  className="message"
                />
              </div>
              <div>
                <PreviewLabel>Email preview</PreviewLabel>
                <PreviewContainer>
                  <EmailTemplate logoUrl={company.logoUrl} website={company.website} address={company.address}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: previewTextWithValues(convertQuillFormatToText(emailNotificationText))
                      }}
                    />
                  </EmailTemplate>
                </PreviewContainer>
              </div>
            </Previews>
          )}

          {selectedTab.id === 'sms' && (
            <Previews>
              <div>
                <RichTextField
                  getTokens={getTokens}
                  isToolbarHiddenCompletely
                  placeholder=""
                  label="SMS message"
                  name="smsNotificationText"
                  control={control}
                  clearOnUnmount={false}
                />
              </div>
              <div>
                <PreviewLabel>SMS preview</PreviewLabel>
                <PreviewContainer>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: previewTextWithValues(
                        clearQuillValueForSms(convertQuillFormatToText(smsNotificationText)),
                        true
                      )
                    }}
                  />
                </PreviewContainer>
              </div>
            </Previews>
          )}
        </Templates>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose} variant={ButtonVariant.Secondary}>
          Cancel
        </Button>
        <Button type="submit" variant={ButtonVariant.Primary}>
          Update
        </Button>
      </ModalFooter>
    </Form>
  );
};
