import { useQuery } from 'react-query';
import { ReactQueryKey } from '@enums';
import { apiErrorHandler } from '@utils';
import { UseQueryOptions } from 'react-query/types/react/types';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';
import { SystemsConnection } from '@generated/types/graphql';

export type IntegrationBudget = {
  totalCount: number;
  monitoredCount: number;
};

export const useIntegrationBudget = (integrationId: number, opts: UseQueryOptions<IntegrationBudget> = {}) =>
  useQuery<IntegrationBudget>(
    [ReactQueryKey.Integration, 'useIntegrationBudget', integrationId],
    async () => {
      try {
        const data = await postGraphql<{ total: SystemsConnection; monitored: SystemsConnection }>(
          gql`
            query INTEGRATION_BUDGET_QUERY($integrationId: Int!) {
              total: systemsConnection(condition: { integrationId: $integrationId }) {
                totalCount
              }

              monitored: systemsConnection(condition: { integrationId: $integrationId, monitored: true }) {
                totalCount
              }
            }
          `,
          {
            integrationId
          }
        );

        return {
          totalCount: data.total.totalCount,
          monitoredCount: data.monitored.totalCount
        };
      } catch (e) {
        throw apiErrorHandler('Error fetching systems stats', e);
      }
    },
    {
      initialData: {
        totalCount: 0,
        monitoredCount: 0
      },
      ...opts
    }
  );
