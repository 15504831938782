import React from 'react';

interface Props {
  logoUrl?: string | null;
  website?: string | null;
  address?: string | null;
  children: React.ReactNode;
}

export const getButtonHtml = (href: string, text: string) => {
  return `
    <a href="${href}"
      style="line-height:100%;text-decoration:none;display:inline-block;max-width:100%;mso-padding-alt:0px;background-color:#009688;border-radius:4px;color:#ffffff;font-size:14px;font-weight:500;padding:11px 16px 11px 16px;text-transform:uppercase;letter-spacing:0.7px"
      target="_blank"><span><!--[if mso]><i style="mso-font-width:400%;mso-text-raise:16.5" hidden>&#8202;&#8202;</i><![endif]--></span><span
        style="max-width:100%;display:inline-block;line-height:120%;mso-padding-alt:0px;mso-text-raise:8.25px">${text}</span><span><!--[if mso]><i style="mso-font-width:400%" hidden>&#8202;&#8202;&#8203;</i><![endif]--></span></a>
    `;
};

export const EmailTemplate = ({ logoUrl, website, address, children }: Props) => {
  return (
    <div
      style={{
        fontWeight: 400,
        fontFamily: 'system-ui, sans-serif',
        backgroundColor: '#ffffff'
      }}
    >
      <table
        align="center"
        width="100%"
        border={0}
        cellPadding="0"
        cellSpacing="0"
        role="presentation"
        style={{
          maxWidth: '37.5em',
          margin: '0 auto'
        }}
      >
        <tbody>
          <tr style={{ width: '100%' }}>
            <td>
              {logoUrl && (
                <img
                  alt=""
                  height="54"
                  src={logoUrl}
                  style={{
                    display: 'block',
                    outline: 'none',
                    border: 'none',
                    textDecoration: 'none'
                  }}
                  width="auto"
                />
              )}
              <p
                style={{
                  fontSize: '14px',
                  lineHeight: '24px',
                  margin: '16px 0'
                }}
              >
                {children}
              </p>
              {(website || address) && (
                <p
                  style={{
                    fontSize: '12px',
                    lineHeight: '24px',
                    margin: '16px 0',
                    padding: '24px 0',
                    borderTop: '1px solid #DFDFE8'
                  }}
                >
                  {website && (
                    <a
                      href={website}
                      style={{
                        color: '#009688',
                        textDecoration: 'none',
                        fontSize: '14px',
                        fontWeight: '500',
                        marginBottom: '16px'
                      }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {website.replace('https://', '')}
                    </a>
                  )}
                  <br />
                  {address}
                </p>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
