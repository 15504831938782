import { useQuery } from 'react-query';
import { ReactQueryKey } from '@enums';
import { apiErrorHandler } from '@utils';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';
import { GeoEstimation } from '@generated/types/graphql';
import { UseQueryOptions } from 'react-query/types/react/types';
import { geolocationRefetchPeriod } from '@hooks/geolocation/constants';

export const useETA = (args: { userId: number }, opts: UseQueryOptions<GeoEstimation | null>) =>
  useQuery<GeoEstimation | null>(
    [ReactQueryKey.Geolocation, 'useETA', args],
    async () => {
      try {
        return (
          (
            await postGraphql<{ geoEstimations: GeoEstimation[] }>(
              gql`
                query ETA_QUERY($userId: Int!) {
                  geoEstimations(
                    filter: { location: { userId: { equalTo: $userId } } }
                    orderBy: [CREATED_AT_DESC]
                    first: 1
                  ) {
                    id
                    taskId
                    duration
                    location {
                      userId
                      taskId
                    }
                  }
                }
              `,
              args
            )
          ).geoEstimations[0] || null
        );
      } catch (e) {
        throw apiErrorHandler('Error fetching user route', e);
      }
    },
    {
      keepPreviousData: true,
      refetchInterval: () => geolocationRefetchPeriod,
      refetchIntervalInBackground: true,
      ...opts
    }
  );
