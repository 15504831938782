import { ReactQueryKey } from '@enums';
import { ImportHistoryStatus, ImportRowStatus } from '@generated/types/graphql';
import { postGraphql } from '@services/api/base/graphql';
import { apiErrorHandler } from '@utils/api';
import { gql } from 'graphql-request';
import { useQuery } from 'react-query';
import { selectWorkspaceId } from '@state/selectors';
import { useAppSelector } from '..';

export type ImportHistoryListItem = {
  id: number;
  createdAt: string;
  createdByUser: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
  };
  status: ImportHistoryStatus;
  type: string;
  totalRows: number;
  importRowsConnection: {
    totalCount: number;
    importStatusCount: {
      status: ImportRowStatus[];
      count: {
        id: string;
      };
    }[];
  };
};

export const useImportHisotryList = () => {
  const companyId = useAppSelector(selectWorkspaceId);

  return useQuery([ReactQueryKey.ImportHistory, companyId], async () => {
    try {
      const data = await postGraphql<{ importHistoriesConnection: { nodes: ImportHistoryListItem[] } }>(
        gql`
          query IMPORT_HISTORY_LIST_QUERY($companyId: Int) {
            importHistoriesConnection(filter: { companyId: { equalTo: $companyId } }, orderBy: CREATED_AT_DESC) {
              nodes {
                id
                createdAt
                createdByUser {
                  id
                  firstName
                  lastName
                  email
                }
                status
                type
                totalRows
                importRowsConnection(orderBy: [IMPORT_ROW_NUMBER_ASC]) {
                  importStatusCount: groupedAggregates(groupBy: [STATUS]) {
                    status: keys
                    count: distinctCount {
                      id
                    }
                  }
                }
              }
            }
          }
        `,

        { companyId }
      );

      return data.importHistoriesConnection.nodes;
    } catch (e) {
      throw apiErrorHandler('Error fetching import history', e);
    }
  });
};
