import React, { PropsWithChildren } from 'react';
import { Integration } from '@generated/types/graphql';
import { useDeleteIntegration, useIntegrationBudget } from '@hooks/integrations';
import { useIntegrationProgress } from '@hooks/integrations/useIntegrationProgress';
import { AlertTriangle } from 'react-feather';
import { colors } from '@styles';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { useConfirmDeleteModal } from '@common/PromiseModal';
import { useMonitorAllSystems } from '@hooks/systems/useMonitorAllSystems';
import { useIntervalEffect } from '@react-hookz/web';
import {
  BetaAlert,
  BetaHeader,
  BetaSubtitle,
  BetaTitle,
  Buttons,
  Container,
  Loader,
  LoaderContainer,
  LoaderLabel,
  MonitorBody,
  MonitorHeader,
  MonitorPanel,
  MonitorTitle
} from './styled';

type Props = PropsWithChildren<{
  integration: Integration;
}>;

export const IntegrationSettings = ({ integration, children }: Props) => {
  const { data: progress, refetch: fetchProgress } = useIntegrationProgress(integration.id, { enabled: false });

  const isInProgress = progress && progress.inProgress;

  useIntervalEffect(() => fetchProgress(), !progress || isInProgress ? 1000 : undefined);

  const {
    data: { totalCount, monitoredCount }
  } = useIntegrationBudget(integration.id, {
    enabled: !isInProgress
  });

  const { mutateAsync: remove } = useDeleteIntegration();
  const confirmDelete = useConfirmDeleteModal();
  const uninstall = async () => {
    if (
      await confirmDelete(
        'Are you sure you want to uninstall integration with SolarEdge? All your SolarEdge systems will be deleted!',
        'Uninstall'
      )
    ) {
      await remove(integration.id);
    }
  };

  const { mutateAsync: monitorAll } = useMonitorAllSystems();

  if (!progress) {
    return null;
  }

  if (isInProgress) {
    return (
      <LoaderContainer>
        <Loader value={progress.total ? (progress.current * 100) / progress.total : 0} />
        <LoaderLabel>
          Loading systems {progress.current}/{progress.total}...
        </LoaderLabel>
      </LoaderContainer>
    );
  }

  return (
    <Container>
      <Buttons>
        {integration.active ? (
          <Button
            variant={ButtonVariant.Secondary}
            onClick={() => monitorAll({ integrationId: integration.id, monitor: false })}
          >
            Pause monitoring
          </Button>
        ) : (
          <Button
            variant={ButtonVariant.Primary}
            onClick={() => monitorAll({ integrationId: integration.id, monitor: true })}
          >
            Restart monitoring
          </Button>
        )}
        <Button variant={ButtonVariant.Danger} onClick={uninstall}>
          Uninstall integration
        </Button>
      </Buttons>

      <BetaAlert>
        <BetaHeader>
          <AlertTriangle size={24} color={colors.yellow} />
          <BetaTitle>Coperniq Fleet is running in Beta.</BetaTitle>
        </BetaHeader>
        <BetaSubtitle>Only 100 systems are available for monitoring for now.</BetaSubtitle>
      </BetaAlert>

      <MonitorPanel>
        <MonitorHeader>
          <MonitorTitle>Total / monitored systems</MonitorTitle>
        </MonitorHeader>
        <MonitorBody>
          {totalCount} / {monitoredCount}
        </MonitorBody>
      </MonitorPanel>

      {children}
    </Container>
  );
};
