import React, { useCallback, useEffect, useMemo } from 'react';
import { useAppSelector } from '@hooks/store';
import { selectCompanyById, selectWorkspaceId } from '@state/selectors';
import { useUpdateCompany } from '@hooks/useUpdateCompany';
import { Form, FormValidationRules, InputField, SelectField, useForm } from '@kit/components/Form';
import { Company } from '@types';
import { isEqual } from 'lodash';
import { validateUrl } from '@utils/validations';
import { useTimezoneList } from '@hooks/useTimezoneList';
import { Sections, Description, H2, Header, Title, Container } from './styled';
import { CompanyLogo } from './CompanyLogo';

interface FormValues {
  name: string;
  address: string;
  website: string;
  timezone: string | { value: string; label: string };
}

const getInitialValues = (company: Company) => ({
  name: company.name,
  address: company.address || '',
  website: company.website || '',
  timezone: company.timezone || ''
});

const utcOffsetToString = (utcOffsetInMinutes: number) => {
  const hours = Math.abs(utcOffsetInMinutes / 60);
  const minutes = Math.abs(utcOffsetInMinutes % 60);
  const sign = utcOffsetInMinutes < 0 ? '-' : '+';

  return `UTC${sign}${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
};

export const GeneralSettings = () => {
  const companyId = useAppSelector(selectWorkspaceId);
  const company = useAppSelector((state) => selectCompanyById(state, companyId));
  const { data: timezones } = useTimezoneList();
  const { mutateAsync: updateCompany } = useUpdateCompany();

  const postForm = async (formValues: FormValues) => {
    if (isEqual(formValues, getInitialValues(company))) {
      return;
    }

    await updateCompany({
      name: formValues.name.trim(),
      address: formValues.address.trim(),
      website: formValues.website.trim().toLowerCase(),
      timezone: typeof formValues.timezone === 'string' ? formValues.timezone : formValues.timezone.value
    });
  };

  const { handleSubmit, form } = useForm<FormValues>({
    onSubmit: postForm
  });

  const { control, reset } = form;

  const rules = useMemo<FormValidationRules<FormValues>>(
    () => ({
      name: {
        isRequired: true
      },
      website: {
        validate: validateUrl
      },
      timezone: {
        isRequired: true
      }
    }),
    []
  );

  useEffect(() => {
    if (company) {
      reset(getInitialValues(company));
    }
  }, [company, reset]);

  const handleChanged = useCallback(() => {
    handleSubmit();
  }, [handleSubmit]);

  const timezoneOptions = useMemo(() => {
    if (!timezones) {
      return [];
    }

    return timezones.map((timezone) => ({
      value: timezone.name,
      label: `(${utcOffsetToString(timezone.utcOffset)}) ${timezone.name}`
    }));
  }, [timezones]);

  return (
    <Container>
      <Header>
        <Title>General settings</Title>
        <Description>Configure your company general settings</Description>
      </Header>

      <Sections>
        <div>
          <H2>Company information</H2>

          <Form rules={rules} onSubmit={handleSubmit}>
            <InputField onBlur={handleChanged} control={control} name="name" label="Your company name" />
            <InputField onBlur={handleChanged} control={control} name="address" label="Your company address" />
            <InputField onBlur={handleChanged} control={control} name="website" label="Your company website" />
            <SelectField
              onBlur={handleChanged}
              control={control}
              name="timezone"
              label="Your company timezone"
              options={timezoneOptions}
              isClearable={false}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
            />
          </Form>
        </div>
        <CompanyLogo />
      </Sections>
    </Container>
  );
};
