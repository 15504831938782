import React, { useCallback, useMemo } from 'react';
import {
  IntFilter,
  MainUser,
  Label,
  PrivilegedTasksConnection,
  TeamsConnection,
  TaskStatus,
  TaskStatusEntity
} from '@generated/types/graphql';
import { DeepPartial } from 'redux';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';
import { useQuery } from 'react-query';
import { capitalize, groupBy } from 'lodash';
import { Briefcase, Clock, Layout, Plus, Tag } from 'react-feather';
import { AccountIcon } from '@common/icons/Account';
import { PriorityIcon } from '@common/PriorityIcon';
import { useAppSelector } from '@hooks/store';
import { selectCurrentUser, selectWorkspaceId } from '@state/selectors';
import { TaskViewFilterSubType } from '@types';
import { colors } from '@styles';
import { REMINDER_OPTIONS } from '@common/Reminder/ReminderTypeSelector';
import { WorkOrderTypeIcon } from '@common/WorkOrderTypeIcon';
import { useUserRoleSettings } from '@hooks/useRoles';
import { hasAccess } from '@utils/roles';
import { CalendarIcon } from '@kit/ui/icons/Calendar';
import { Button, ButtonSize, ButtonVariant } from '@kit/ui/Button';
import { Tooltip } from '@material-ui/core';
import { CLIENT_STATUS_OPTIONS, PROJECT_STATUS_OPTIONS, REQUEST_STATUS_OPTIONS } from '@features/Analytics/constants';
import { BriefcaseIcon } from '@kit/ui/icons/Briefcase';
import { DollarIcon } from '@kit/ui/icons/Dollar';
import { MoreFilters } from '../Filters/MoreFilters';
import { FilterWithGroups } from '../Filters/FilterWithGroups';
import { ResoucesFilter } from '../Filters/ResourcesFilter';
import { Chip, ChipItem, Total, ChipsContainer, Container, Filters, Right, TopRow, ChipsInPopover } from './styled';
import { Views } from './Views';
import { EventFilter } from '../Filters/EventFilter';
import { Search } from '../Search';

const fetchFilterOptions = async (companyId: number) => {
  const companyIdFilter: DeepPartial<IntFilter> = { equalTo: companyId };

  const {
    assignees,
    templates: { nodes: templates },
    labels,
    teams: { nodes: teams },
    statuses
  } = await postGraphql<{
    assignees: MainUser[];
    templates: PrivilegedTasksConnection;
    labels: Label[];
    teams: TeamsConnection;
    statuses: TaskStatusEntity[];
  }>(
    gql`
      query SCHEDULER_FILTER_OPTIONS_QUERY($companyIdFilter: IntFilter) {
        assignees: mainUsers(
          filter: { companyUsersByUserId: { some: { companyId: $companyIdFilter, isActive: { equalTo: true } } } }
        ) {
          id
          firstName
          lastName
          avatarUrl
        }

        templates: privilegedTasksConnection(
          filter: { companyId: $companyIdFilter, isTemplate: { equalTo: true }, isArchived: { equalTo: false } }
        ) {
          nodes {
            id
            title
            isField
          }
        }

        labels(filter: { isActive: { equalTo: true }, companyId: $companyIdFilter }) {
          id
          label
        }

        teams: teamsConnection(filter: { companyId: $companyIdFilter }) {
          nodes {
            id
            name
            teamUsers {
              id
            }
          }
        }

        statuses: taskStatuses {
          id
          label
        }
      }
    `,
    { companyIdFilter }
  );

  return {
    assignees,
    templates,
    labels,
    teams,
    statuses
  };
};

export const useFilterOptions = () => {
  const companyId = useAppSelector(selectWorkspaceId);

  return useQuery(['dispatcher', 'filters'], () => fetchFilterOptions(companyId));
};

const useFilters = () => {
  const { data: filterOptions } = useFilterOptions();

  return useMemo(
    () => ({
      clientStatuses: CLIENT_STATUS_OPTIONS,
      projectStatuses: PROJECT_STATUS_OPTIONS,
      requestStatuses: REQUEST_STATUS_OPTIONS,
      assignees: filterOptions?.assignees ?? [],
      templates: filterOptions?.templates ?? [],
      labels: filterOptions?.labels ?? [],
      teams: (filterOptions?.teams ?? []).filter((team) => team.teamUsers.length > 0),
      statuses: (filterOptions?.statuses ?? []).map(({ id, label }) => ({ id, title: label }))
    }),
    [filterOptions]
  );
};

const CHIP_ICONS = {
  projectStatus: <Briefcase color="#98A9BC" size="12px" />,
  clientStatus: <AccountIcon color="#98A9BC" size={12} />,
  requestStatus: <DollarIcon color="#98A9BC" size={12} />,
  template: <Layout color="#98A9BC" size="12px" />,
  label: <Tag color="#734DBA" size="12px" />,
  reminder: <Clock color={colors.green} size="12px" />
};

const PRIORITY_OPTIONS = [
  { id: 1, title: 'High', icon: <PriorityIcon priority={1} size="12px" /> },
  { id: 2, title: 'Medium', icon: <PriorityIcon priority={2} size="12px" /> },
  { id: 3, title: 'Low', icon: <PriorityIcon priority={3} size="12px" /> }
];

// TODO need to refactor all of these

const useChips = (selectedFilters: any) => {
  const filters = useFilters();

  return useMemo(() => {
    const { clientStatuses, requestStatuses, projectStatuses, assignees, templates, labels, statuses } = filters;

    const clientStatusesSelected = (selectedFilters.clientStatuses || [])
      .map((id: number) => clientStatuses.find((option) => option.id === id))
      .filter(Boolean);
    const projectStatusesSelected = (selectedFilters.projectStatuses || [])
      .map((id: number) => projectStatuses.find((option) => option.id === id))
      .filter(Boolean);
    const requestStatusesSelected = (selectedFilters.requestStatuses || [])
      .map((id: number) => requestStatuses.find((option) => option.id === id))
      .filter(Boolean);
    const assigneesSelected = (selectedFilters.assignees || [])
      .map((id: number) =>
        id === 'unassigned' ? { id, firstName: 'Unassigned' } : assignees.find((option) => option.id === id)
      )
      .filter(Boolean);
    const templatesSelected = (selectedFilters.templates || [])
      .map((id: number) => templates.find((option) => option.id === id))
      .filter(Boolean);
    const labelsSelected = (selectedFilters.labels || [])
      .map((id: number) => labels.find((option) => option.id === id))
      .filter(Boolean);
    const prioritiesSelected = (selectedFilters.priorities || [])
      .map((id: number) => PRIORITY_OPTIONS.find((option) => option.id === id))
      .filter(Boolean);
    const statusesSelected = (selectedFilters.statuses || [])
      .map((id: TaskStatus) => statuses.find((option) => option.id === id))
      .filter(Boolean);
    const reminderTypesSelected = (selectedFilters.reminderTypes || [])
      .map(
        (id: string) =>
          REMINDER_OPTIONS.find((option) => option.id === id) ||
          (id === 'appointment'
            ? { id, name: 'Appointment', icon: { component: <CalendarIcon size="16px" color="#9C9CAA" /> } }
            : null)
      )
      .filter(Boolean);

    const chips = [];

    if (clientStatusesSelected.length > 0 && clientStatusesSelected.length === CLIENT_STATUS_OPTIONS.length) {
      chips.push({ title: 'All Clients', type: 'clientStatus' });
    } else if (clientStatusesSelected.length > 0) {
      chips.push(
        ...clientStatusesSelected.map((option: any) => ({ ...option, title: option.name, type: 'clientStatus' }))
      );
    }

    if (projectStatusesSelected.length > 0 && projectStatusesSelected.length === PROJECT_STATUS_OPTIONS.length) {
      chips.push({ title: 'All Projects ', type: 'projectStatus' });
    } else if (projectStatusesSelected.length > 0) {
      chips.push(
        ...projectStatusesSelected.map((option: any) => ({ ...option, title: option.name, type: 'projectStatus' }))
      );
    }

    if (requestStatusesSelected.length > 0 && requestStatusesSelected.length === REQUEST_STATUS_OPTIONS.length) {
      chips.push({ title: 'All Requests', type: 'requestStatus' });
    } else if (requestStatusesSelected.length > 0) {
      chips.push(
        ...requestStatusesSelected.map((option: any) => ({ ...option, title: option.name, type: 'requestStatus' }))
      );
    }

    if (assigneesSelected.length > 0 && assigneesSelected.length === assignees.length) {
      chips.push({ title: 'All assignees', type: 'assignee' });
    } else if (assigneesSelected.length > 0) {
      chips.push(...assigneesSelected.map((option: any) => ({ ...option, type: 'assignee' })));
    }

    if (templatesSelected.length > 0 && templatesSelected.length === templates.length) {
      chips.push({ title: 'All Templates', type: 'template' });
    } else if (templatesSelected.length > 0) {
      chips.push(...templatesSelected.map((option: any) => ({ ...option, type: 'template' })));
    }

    if (labelsSelected.length > 0 && labelsSelected.length === labels.length) {
      chips.push({ title: 'All Labels', type: 'label' });
    } else if (labelsSelected.length > 0) {
      chips.push(...labelsSelected.map((option: any) => ({ ...option, title: option.label, type: 'label' })));
    }

    if (prioritiesSelected.length > 0 && prioritiesSelected.length === PRIORITY_OPTIONS.length) {
      chips.push({ title: 'All priorities', type: 'priority' });
    } else if (prioritiesSelected.length > 0 && prioritiesSelected.length < PRIORITY_OPTIONS.length) {
      chips.push(...prioritiesSelected.map((option: any) => ({ ...option, type: 'priority' })));
    }

    if (statusesSelected.length > 0 && statusesSelected.length === statuses.length) {
      chips.push({ title: 'All statuses', type: 'status' });
    } else if (statusesSelected.length > 0 && statusesSelected.length < statuses.length) {
      chips.push(...statusesSelected.map((option: any) => ({ ...option, type: 'status' })));
    }

    // + 1 since there is hardcoded "Appointment" option in "reminderTypes" filter
    if (reminderTypesSelected.length > 0 && reminderTypesSelected.length === REMINDER_OPTIONS.length + 1) {
      chips.push({ title: 'All Reminders', type: 'reminder' });
    } else if (reminderTypesSelected.length > 0) {
      chips.push(
        ...reminderTypesSelected.map((option: any) => ({ ...option, title: capitalize(option.name), type: 'reminder' }))
      );
    }

    return groupBy(chips, (chip) => chip.type);
  }, [selectedFilters, filters]);
};

const Chips = ({ selectedFilters, onClear }: { selectedFilters: any; onClear: () => void }) => {
  const chipGroups = useChips(selectedFilters);

  return (
    <>
      {Object.keys(chipGroups).map((group) => {
        const items = chipGroups[group];

        const icon = CHIP_ICONS[group];

        if (group === 'priority') {
          return (
            <Chip key={group}>
              <ChipItem>
                {items[0].id && <PriorityIcon size="12px" priority={items[0].id} />}
                {items[0].title}
              </ChipItem>
              {items.length > 1 && (
                <Tooltip
                  title={
                    <ChipsInPopover>
                      {items.slice(1).map((item) => (
                        <ChipItem key={item.id}>
                          {item.id && <PriorityIcon size="12px" priority={item.id} />}
                          {item.title}
                        </ChipItem>
                      ))}
                    </ChipsInPopover>
                  }
                >
                  <ChipItem>, +{items.length - 1}</ChipItem>
                </Tooltip>
              )}
            </Chip>
          );
        }

        if (group === 'reminder') {
          return (
            <Chip key={group}>
              <ChipItem>
                {items[0].icon?.component ? React.cloneElement(items[0].icon.component, { size: '12px' }) : icon}
                {items[0].title}
              </ChipItem>
              {items.length > 1 && (
                <Tooltip
                  title={
                    <ChipsInPopover>
                      {items.slice(1).map((item) => (
                        <ChipItem key={item.id}>
                          {item.icon?.component ? React.cloneElement(item.icon.component, { size: '12px' }) : icon}
                          {item.title}
                        </ChipItem>
                      ))}
                    </ChipsInPopover>
                  }
                >
                  <ChipItem>, +{items.length - 1}</ChipItem>
                </Tooltip>
              )}
            </Chip>
          );
        }

        if (group === 'assignee') {
          if (items[0].title === 'All assignees') {
            return <Chip key="group">All assignees</Chip>;
          }

          return (
            <Chip key={group}>
              <ChipItem>
                {items[0].firstName} {items[0].lastName}
              </ChipItem>
              {items.length > 1 && (
                <Tooltip
                  title={
                    <ChipsInPopover>
                      {items.slice(1).map((item) => (
                        <ChipItem key={item.id}>
                          {item.firstName} {item.lastName}
                        </ChipItem>
                      ))}
                    </ChipsInPopover>
                  }
                >
                  <ChipItem>, +{items.length - 1}</ChipItem>
                </Tooltip>
              )}
            </Chip>
          );
        }

        return (
          <Chip key={group}>
            <ChipItem>
              {icon}
              {items[0].title}
            </ChipItem>
            {items.length > 1 && (
              <Tooltip
                title={
                  <ChipsInPopover>
                    {items.slice(1).map((item) => (
                      <ChipItem key={item.id}>
                        {icon}
                        {item.title}
                      </ChipItem>
                    ))}
                  </ChipsInPopover>
                }
              >
                <ChipItem>, +{items.length - 1}</ChipItem>
              </Tooltip>
            )}
          </Chip>
        );
      })}
      {Object.keys(chipGroups).length > 0 && (
        <Button isUpperCase={false} variant={ButtonVariant.Flat} size={ButtonSize.Small} onClick={onClear}>
          Clear all
        </Button>
      )}
    </>
  );
};

const TotalAndChips = ({ visibleCount, totalCount, selectedFilters, onClear }: any) => {
  return (
    <ChipsContainer>
      <Total>Work orders: {visibleCount === totalCount ? totalCount : `${visibleCount}/${totalCount}`}</Total>
      <Chips selectedFilters={selectedFilters} onClear={onClear} />
    </ChipsContainer>
  );
};

interface Props {
  totalCount: number;
  visibleCount: number;
  selectedFilters: any;
  onFilterChange: any;
  onAddNewTask: () => void;
  subType: TaskViewFilterSubType;
  className?: string;
}

export const Header = ({
  className,
  subType,
  totalCount,
  visibleCount,
  selectedFilters,
  onFilterChange,
  onAddNewTask
}: Props) => {
  const companyId = useAppSelector(selectWorkspaceId);

  const user = useAppSelector(selectCurrentUser);

  const { data: access } = useUserRoleSettings(companyId, user.userId);

  const { clientStatuses, projectStatuses, requestStatuses, assignees, templates, labels, teams, statuses } =
    useFilters();

  const updateFilter = useCallback(
    (filter: any) => {
      onFilterChange((prev: any) => ({
        ...prev,
        ...filter
      }));
    },
    [onFilterChange]
  );

  const handleClear = useCallback(() => {
    onFilterChange((prev: any) => ({
      ...prev,
      clientStatuses: [],
      projectStatuses: [],
      requestStatuses: [],
      assignees: [],
      templates: [],
      statuses: [],
      labels: [],
      priorities: [],
      reminderTypes: []
    }));
  }, [onFilterChange]);

  return (
    <Container className={className}>
      <TopRow>
        <Filters>
          <Search />
          <EventFilter
            label="Record"
            onSelect={updateFilter}
            groups={[
              {
                fieldName: 'clientStatuses',
                title: 'CLIENTS',
                options: clientStatuses.map((option) => ({ ...option, title: capitalize(option.name) })),
                icon: <AccountIcon size={16} color="#9C9CAA" />
              },
              {
                fieldName: 'requestStatuses',
                title: 'REQUESTS',
                options: requestStatuses.map((option) => ({ ...option, title: capitalize(option.name) })),
                icon: <DollarIcon size={16} color="#9C9CAA" />
              },
              {
                fieldName: 'projectStatuses',
                title: 'PROJECTS',
                options: projectStatuses.map((option) => ({ ...option, title: capitalize(option.name) })),
                icon: <BriefcaseIcon size={16} color="#9C9CAA" />
              }
            ]}
            selected={{
              clientStatuses: selectedFilters.clientStatuses,
              projectStatuses: selectedFilters.projectStatuses,
              requestStatuses: selectedFilters.requestStatuses
            }}
          />
          <ResoucesFilter
            label="Resource"
            fieldName="assignees"
            selected={selectedFilters.assignees}
            onSelect={updateFilter}
            assignees={assignees}
            teams={teams}
          />

          <EventFilter
            label="Event"
            onSelect={updateFilter}
            groups={[
              {
                fieldName: 'templates',
                title: 'Office work order',
                options: templates.filter((template) => !template.isField),
                icon: <WorkOrderTypeIcon size="16px" isField={false} />
              },
              {
                fieldName: 'templates',
                title: 'Field work order',
                options: templates.filter((template) => template.isField),
                icon: <WorkOrderTypeIcon size="16px" isField />
              },
              {
                fieldName: 'reminderTypes',
                title: 'Appointment & Reminder',
                options: [
                  {
                    id: 'appointment',
                    title: 'Appointment',
                    icon: { component: <CalendarIcon size="16px" color="#9C9CAA" /> }
                  }
                ].concat(REMINDER_OPTIONS.map((option) => ({ ...option, title: capitalize(option.name) }))),
                icon: <Clock size="16px" color="#9C9CAA" />
              }
            ]}
            selected={{
              templates: selectedFilters.templates,
              reminderTypes: selectedFilters.reminderTypes
            }}
          />

          <FilterWithGroups
            label="Status"
            fieldName="statuses"
            onSelect={updateFilter}
            groups={[{ title: 'Status', options: statuses }]}
            selected={selectedFilters.statuses}
          />

          <MoreFilters
            filterGroups={[
              {
                title: 'Labels',
                fieldName: 'labels',
                isSearchbale: false,
                options: labels.map((label) => ({ ...label, title: label.label })),
                selected: selectedFilters.labels
              },
              {
                title: 'Priority',
                fieldName: 'priorities',
                isSearchbale: false,
                options: PRIORITY_OPTIONS,
                selected: selectedFilters.priorities
              }
            ]}
            onSelect={updateFilter}
          />
        </Filters>
        <Right>
          <Views subType={subType} />
          {hasAccess(access, 'task', 'create') && (
            <Button variant={ButtonVariant.Primary} onClick={onAddNewTask}>
              <Plus size="16px" />
              Work order
            </Button>
          )}
        </Right>
      </TopRow>

      <TotalAndChips
        visibleCount={visibleCount}
        totalCount={totalCount}
        selectedFilters={selectedFilters}
        onClear={handleClear}
      />
    </Container>
  );
};
