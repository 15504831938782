import { ReactQueryKey } from '@enums';
import { EntityType, EventType, SingleDestination } from '@generated/types/graphql';
import { useAppSelector } from '@hooks/store';
import notificationsSettingsApi from '@services/api/notificationsSettingsApi';
import { selectWorkspaceId } from '@state/selectors';
import { apiErrorHandler } from '@utils/api';
import { useMutation, useQueryClient } from 'react-query';
import { produce } from 'immer';
import { CompanyNoficationSettingsTreeItem } from './useCompanyNotifications';
import { PortalNoficationSettingsTreeItem } from './usePortalNotifications';

type Params = {
  entityType: EntityType;
  eventType: EventType;
  smsEnabled?: boolean;
  emailEnabled?: boolean;
  pushEnabled?: boolean;
  inboxEnabled?: boolean;
  enabled?: boolean;
  singleDestinations?: SingleDestination[];
  roleDestinations?: number[];
  teamDestinations?: number[];
  offset1Time?: number;
  offset1Unit?: string;
  offset1Email?: boolean;
  offset1Sms?: boolean;
  offset2Time?: number;
  offset2Unit?: string;
  offset2Email?: boolean;
  offset2Sms?: boolean;
  emailNotificationSubject?: string;
  emailNotificationText?: string;
  smsNotificationText?: string;
};

export const useUpdateCompanyNotification = () => {
  const queryClient = useQueryClient();
  const companyId = useAppSelector(selectWorkspaceId);

  return useMutation<string, Error, Params>(
    async (params) => {
      try {
        await notificationsSettingsApi.updateNoficationSetting([params]);
      } catch (e) {
        throw apiErrorHandler('Error updating notification settings', e);
      }
    },
    {
      onMutate(variables) {
        /*
          Two almost identical blocks of code, but with different types of data.
          TODO: Refactor this to avoid code duplication.
        */
        queryClient.setQueryData<CompanyNoficationSettingsTreeItem[]>(
          [ReactQueryKey.WorkspaceNotificationsConfig, companyId],
          (oldData) => {
            if (!oldData) {
              return oldData;
            }

            const entityTypeIndex = oldData.findIndex((item) => item.entityType === variables.entityType);
            if (entityTypeIndex === -1) {
              return oldData;
            }

            const eventTypeIndex = oldData[entityTypeIndex].events.findIndex(
              (item) => item.settings.eventType === variables.eventType
            );

            if (eventTypeIndex === -1) {
              return oldData;
            }

            if (typeof variables.enabled !== 'undefined') {
              return produce(oldData, (draft) => {
                // eslint-disable-next-line no-param-reassign
                draft[entityTypeIndex].events[eventTypeIndex].settings.enabled = variables.enabled;
              });
            }

            return oldData;
          }
        );

        queryClient.setQueryData<PortalNoficationSettingsTreeItem[]>(
          [ReactQueryKey.WorkspacePortalNotificationsConfig, companyId],
          (oldData) => {
            if (!oldData) {
              return oldData;
            }

            const entityTypeIndex = oldData.findIndex((item) => item.entityType === variables.entityType);
            if (entityTypeIndex === -1) {
              return oldData;
            }

            const eventTypeIndex = oldData[entityTypeIndex].events.findIndex(
              (item) => item.settings.eventType === variables.eventType
            );

            if (eventTypeIndex === -1) {
              return oldData;
            }

            if (typeof variables.enabled !== 'undefined') {
              return produce(oldData, (draft) => {
                // eslint-disable-next-line no-param-reassign
                draft[entityTypeIndex].events[eventTypeIndex].settings.enabled = variables.enabled;
              });
            }

            return oldData;
          }
        );
      },
      onSuccess: () => {
        queryClient.invalidateQueries([ReactQueryKey.WorkspaceNotificationsConfig, companyId]);
        queryClient.invalidateQueries([ReactQueryKey.WorkspacePortalNotificationsConfig, companyId]);
      }
    }
  );
};
