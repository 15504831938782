import { useQuery } from 'react-query';
import { ReactQueryKey } from '@enums';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';
import { Timezone } from '@generated/types/graphql';

export const useTimezoneList = () =>
  useQuery(
    [ReactQueryKey.TimezoneList],
    async () => {
      const { timezones } = await postGraphql<{ timezones: Timezone[] }>(gql`
        query TIMEZONE_LIST_QUERY {
          timezones {
            name
            utcOffset
          }
        }
      `);

      return timezones;
    },
    {
      refetchOnMount: false
    }
  );
