import { useQuery } from 'react-query';
import { useAppSelector } from '@hooks';
import { selectWorkspaceId } from '@state/selectors';
import { ReactQueryKey } from '@enums';
import { apiErrorHandler } from '@utils';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';
import { Team } from '@generated/types/graphql';

export const useTeamsWithGeoSettings = () => {
  const companyId = useAppSelector(selectWorkspaceId);

  return useQuery<Team[]>(
    [ReactQueryKey.WorkspaceTeams, companyId, 'useTeamsWithGeoSettings'],
    async () => {
      try {
        return (
          await postGraphql<{ teams: Team[] }>(
            gql`
              query TEAMS_WITH_GEO_SETTINGS_QUERY($companyId: Int!) {
                teams(condition: { companyId: $companyId }) {
                  id
                  name
                  geolocationUserSettingsByTeamId {
                    id
                    enabled
                  }
                }
              }
            `,
            {
              companyId
            }
          )
        ).teams;
      } catch (e) {
        throw apiErrorHandler('Error fetching teams with geo settings', e);
      }
    },
    {
      enabled: !!companyId,
      initialData: [],
      keepPreviousData: true
    }
  );
};
